import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { I18n } from "i18n-js";
import i18nDefaultLocale from "../../constants/i18n";
import companyDefaultAvatar from "../../../assets/images/profile_company_logo.png";
import GlassdoorRating from "../shared/GlassdoorRating";

const i18n = new I18n({
  en: {
    placeholder:
      "Search for jobs, salaries, blogposts and companies using the search bar above!",
    seeAll: "See All",
    jobs: {
      title: "Jobs",
      seeAll: "View all '%{query}' jobs"
    },
    salaries: {
      title: "Salaries",
      seeAll: "View all salaries",
      pastListings: "Past Listings"
    },
    blogposts: {
      title: "Blog Posts"
    },
    companies: {
      title: "Companies",
      employees: "Employees"
    }
  },
  vn: {
    placeholder:
      "Tìm kiếm công việc, mức lương, công ty và bài đăng blog bằng cách sử dụng thanh tìm kiếm ở phía trên!",
    seeAll: "Xem tất cả",
    jobs: {
      title: "Việc Làm",
      seeAll: "Xem tất cả việc làm '%{query}'"
    },
    salaries: {
      title: "Thông Tin Lương",
      seeAll: "Xem tất cả",
      pastListings: "Past Listings"
    },
    blogposts: {
      title: "Bài Viết Blog"
    },
    companies: {
      title: "Công Ty",
      employees: "Người lao động"
    }
  },
  id: {
    placeholder:
      "Cari lowongan kerja, gaji, perusahaan, dan artikel terkait menggunakan kotak pencarian di atas!",
    seeAll: "Lihat Semua",
    jobs: {
      title: "Lowongan",
      seeAll: "Lihat semua lowongan '%{query}'"
    },
    salaries: {
      title: "Daftar Gaji",
      seeAll: "Lihat semua data gaji",
      pastListings: "Past Listings"
    },
    blogposts: {
      title: "Artikel Terkait"
    },
    companies: {
      title: "Perusahaan",
      employees: "Karyawan"
    }
  }
});

export default function SearchSuggestions({
  locale = i18nDefaultLocale,
  sessionCountry,
  searchBarId
}) {
  i18n.locale = locale;
  i18n.enableFallback = true;
  i18n.defaultLocale = i18nDefaultLocale;

  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState({});

  useEffect(() => {
    document.getElementById(searchBarId).addEventListener("keyup", (event) => {
      setQuery(event.target.value);
    });
  }, [searchBarId]);

  useEffect(() => {
    if (query) {
      axios
        .get(`api/v2/search?query=${query}`)
        .then((res) => setSuggestions(res.data))
        .catch(() => setSuggestions({}));
    } else {
      setSuggestions({});
    }
  }, [query]);

  function salaryTextForJobSuggestion(job) {
    if (job.formatted_salary_min && job.formatted_salary_max) {
      return `${job.formatted_salary_min} - ${job.formatted_salary_max}`;
    }
    if (job.salary_min) {
      return `From ${job.formatted_salary_min}`;
    }
    if (job.salary_max) {
      return `Up to ${job.formatted_salary_max}`;
    }
    return ``;
  }

  const renderTechstackSuggestions = () => {
    const techstackSuggestions = suggestions.techstacks;

    if (!techstackSuggestions || techstackSuggestions.length === 0) {
      return null;
    }

    return (
      <div className="search__tech-stack-container">
        {techstackSuggestions.map((techstack) => (
          <a
            key={techstack.id}
            href={`/jobs?tech_stacks%5B%5D=${encodeURIComponent(
              techstack.name
            )}&countries[]=${sessionCountry}`}
          >
            <span className="search__tech-stack">{techstack.name}</span>
          </a>
        ))}
      </div>
    );
  };

  const renderJobSuggestions = () => {
    const jobSuggestions = suggestions.jobs;

    if (!jobSuggestions) {
      return null;
    }

    const jobsUrl = `/jobs?query=${query}&countries[]=${sessionCountry}`;

    if (query && jobSuggestions.length < 1) {
      return (
        <>
          <span className="search__category-title">{i18n.t("jobs.title")}</span>
          {renderTechstackSuggestions()}
          <div className="search text-center">
            <a
              href={jobsUrl}
              className="mt-2 d-block text-center p-2 w-100 text-primary font-weight-bold"
            >
              {i18n.t("jobs.seeAll", { query })}
            </a>
          </div>
        </>
      );
    }

    return (
      <>
        <span className="search__category-title">{i18n.t("jobs.title")}</span>
        {renderTechstackSuggestions()}
        <div className="search">
          {jobSuggestions.map((job) => (
            <a key={job.id} href={job.url}>
              <div className="d-flex p-1">
                <div className="flex-shrink-0">
                  <img
                    src={job.company_avatar || companyDefaultAvatar}
                    className="search__company-avatar"
                    alt={job.company_name}
                  />
                </div>
                <div className="flex-grow-1 px-3">
                  <div className="row">
                    <div className="col">
                      <div className="search__company-name">
                        {job.company_name}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="search__title">{job.title}</div>
                      <p className="d-md-none search__salary--mobile">
                        {salaryTextForJobSuggestion(job)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row d-none d-md-block">
                  <div className="col search__salary search__salary--jobs">
                    {salaryTextForJobSuggestion(job)}
                  </div>
                </div>
              </div>
              <hr className="search__divider" />
            </a>
          ))}
          <a
            href={jobsUrl}
            className="d-block text-center p-2 w-100 text-primary font-weight-bold"
          >
            {i18n.t("jobs.seeAll", { query })}
          </a>
        </div>
      </>
    );
  };

  const renderSalaryCompanySuggestions = () => {
    const salaryCompanySuggestions = suggestions.salaryCompanies;

    if (!salaryCompanySuggestions || salaryCompanySuggestions.length === 0) {
      return null;
    }

    return (
      <div className="search__tech-stack-container">
        {salaryCompanySuggestions.map((company) => (
          <a
            key={company.id}
            href={`/salaries?companies%5B%5D=${encodeURIComponent(
              company.companyName
            )}&countries[]=${sessionCountry}`}
          >
            <span className="search__tech-stack">{company.companyName}</span>
          </a>
        ))}
      </div>
    );
  };

  const renderSalarySuggestions = () => {
    const salarySuggestions = suggestions.salaries;

    if (!salarySuggestions) {
      return null;
    }

    const salariesUrl = `/salaries?countries[]=${sessionCountry}`;

    if (query && salarySuggestions.length < 1) {
      return (
        <>
          <span className="search__category-title">
            {i18n.t("salaries.title")}
          </span>
          <div className="search text-center">
            <a
              href={salariesUrl}
              className="mt-2 d-block text-center p-2 w-100 text-primary font-weight-bold"
            >
              {i18n.t("salaries.seeAll")}
            </a>
          </div>
        </>
      );
    }

    return (
      <>
        <span className="search__category-title">
          {i18n.t("salaries.title")}
        </span>
        {renderSalaryCompanySuggestions()}
        <div className="search">
          {salarySuggestions.map((salary, index) => (
            <a key={index} href={salary.url}>
              <div className="d-flex p-1">
                <div className="flex-shrink-0">
                  <img
                    src={salary.company_avatar || companyDefaultAvatar}
                    className="search__company-avatar"
                    alt={salary.company_name}
                  />
                </div>
                <div className="flex-grow-1 px-3">
                  <div className="row">
                    <div className="col">
                      <div className="search__company-name">
                        {salary.company_name}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="search__title">
                        {salary.position}
                        <span className="font-weight-light">
                          {" "}
                          | {salary.seniority}
                        </span>
                      </div>
                      <p className="d-lg-none search__salary--mobile">
                        {salary.has_user_salaries
                          ? salary.avgSalary
                          : `${salary.salaryMin} - ${salary.salaryMax}`}
                        {salary.has_verified && (
                          <i className="search__verified-icon fa fa-check-circle" />
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row text-right">
                  {salary.has_user_salaries ? (
                    <>
                      <div className="d-none d-lg-block col search__salary">
                        <p className="m-0 p-0 search__salary--salaries">
                          {salary.avgSalary}
                        </p>
                        <p className="m-0 p-0 align-items-center">
                          {salary.salaryCount} {i18n.t("salaries.title")}
                          {salary.has_verified && (
                            <i className="search__verified-icon fa fa-check-circle" />
                          )}
                        </p>
                      </div>
                      <div className="d-lg-none text-center mr-3 ml-3 mt-1">
                        <p className="search__salaries-count--mobile m-0">
                          {salary.salaryCount}
                        </p>
                        <p className="search__salaries-text--mobile m-0">
                          {i18n.t("salaries.title")}
                        </p>
                      </div>
                    </>
                  ) : (
                    <div className="col search__salary">
                      <p className="d-none d-lg-block m-0 p-0 search__salary--salaries">{`${salary.salaryMin} - ${salary.salaryMax}`}</p>
                      <p className="m-0 p-0 mr-1 ml-2 mt-1 search__salaries-text--mobile">
                        {i18n.t("salaries.pastListings")}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <hr className="search__divider" />
            </a>
          ))}
          <a
            href={salariesUrl}
            className="d-block text-center p-2 w-100 text-primary font-weight-bold"
          >
            {i18n.t("salaries.seeAll")}
          </a>
        </div>
      </>
    );
  };

  const renderBlogSuggestions = () => {
    const blogSuggestions = suggestions.blogspots || [];

    if (!Array.isArray(blogSuggestions) || blogSuggestions.length < 1) {
      return null;
    }

    return (
      <>
        <span className="search__category-title">
          {i18n.t("blogposts.title")}
        </span>
        <div className="search">
          {blogSuggestions.map((blogpost, index) => (
            <a key={index} href={blogpost.url}>
              <div className="d-flex p-1">
                <div className="overflow-hidden">
                  <div className="search__title">
                    <u>{blogpost.title}</u>
                  </div>
                  <p className="text-truncate tex-dark search__blog-summary">
                    {blogpost.summary}
                  </p>
                </div>
              </div>
              {index !== blogSuggestions.length - 1 && (
                <hr className="search__divider" />
              )}
            </a>
          ))}
        </div>
      </>
    );
  };

  const renderCompanySuggestions = () => {
    const companySuggestions = suggestions.companies || [];

    if (!Array.isArray(companySuggestions) || companySuggestions.length < 1) {
      return null;
    }

    return (
      <>
        <span className="search__category-title">
          {i18n.t("companies.title")}
        </span>
        <div className="search">
          {companySuggestions.map((company, index) => (
            <a key={company.id} href={company.url}>
              <div className="d-flex align-items-center p-1">
                <div className="flex-shrink-0">
                  <img
                    src={company.avatar || companyDefaultAvatar}
                    className="search__company-avatar"
                    alt={company.companyName}
                  />
                </div>
                <div className="flex-grow-1 px-3">
                  <div className="search__title">{company.companyName}</div>
                  {company.glassdoorRating > 0 && (
                    <div className="d-lg-none mt-1 text-muted">
                      <GlassdoorRating
                        glassdoorRating={company.glassdoorRating}
                      />
                    </div>
                  )}
                </div>
                <div className="text-dark d-none d-lg-block">
                  <p className="m-0 text-right">
                    {company.employeeCount} {i18n.t("companies.employees")}
                  </p>
                  {company.glassdoorRating > 0 && (
                    <span>
                      <GlassdoorRating
                        glassdoorRating={company.glassdoorRating}
                      />
                    </span>
                  )}
                </div>
              </div>
              {index !== companySuggestions.length - 1 && (
                <hr className="search__divider" />
              )}
            </a>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className="search-suggestions">
      {renderJobSuggestions()}
      {renderSalarySuggestions()}
      {renderCompanySuggestions()}
      {renderBlogSuggestions()}
    </div>
  );
}

SearchSuggestions.propTypes = {
  locale: PropTypes.string,
  sessionCountry: PropTypes.string.isRequired,
  searchBarId: PropTypes.string.isRequired
};