import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { I18n } from "i18n-js";
import i18nDefaultLocale from "../../constants/i18n";
import DefaultCompanyAvatar from "../../../assets/images/profile_company_logo.png";
import GlassdoorRating from "../shared/GlassdoorRating";
import cssBreakpoints from "../../constants/cssBreakpoints";
import {
  BLACK,
  LIGHT_BLACK,
  PRIMARY,
  SUBTLE_PRIMARY,
  WHITE
} from "../../constants/colors";

const useStyles = createUseStyles({
  hasUserSalaries: { backgroundColor: SUBTLE_PRIMARY },
  noUserSalaries: { backgroundColor: "#f3f3f3" },
  tagTick: {
    marginTop: "3px",
    marginLeft: "2px",
    color: PRIMARY,
    transition: "all 0.2s"
  },
  tagHolder: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "400px",
    [`@media (max-width: ${cssBreakpoints.tablet})`]: { maxWidth: "100%" }
  },
  tag: {
    position: "relative",
    margin: "0 5px 5px 0px",
    display: "inline-block",
    padding: "8px 12px",
    borderRadius: "8px",
    fontSize: "12px",
    transition: "all 0.2s",
    cursor: "pointer",
    zIndex: "2",
    color: BLACK,
    whiteSpace: "nowrap",
    " &:hover ": {
      backgroundColor: PRIMARY,
      color: WHITE,
      "& $tagTick": {
        color: WHITE
      }
    }
  },
  companiesCard: {
    color: LIGHT_BLACK,
    backgroundColor: WHITE,
    padding: "20px",
    borderRadius: "10px",
    height: "100%",
    position: "relative",
    paddingBottom: "60px"
  },
  companiesLink: {
    display: "block",
    position: "absolute",
    top: "0",
    left: "0",
    height: "100%",
    width: "100%",
    zIndex: "1"
  },
  companiesImg: {
    width: "60px",
    height: "60px",
    borderRadius: "8px",
    marginRight: "20px",
    overflow: "hidden"
  },
  title: { lineHeight: "24px", maxWidth: "80%", width: "80%" },
  more: { position: "absolute", bottom: "20px", right: "20px" }
});

const i18n = new I18n({
  en: {
    morePositionsCTA: "View all positions",
    noInformation: "no company details"
  },
  vn: {
    morePositionsCTA: "Xem tất cả vị trí",
    noInformation: "Không có thông tin công ty"
  },
  id: {
    morePositionsCTA: "Lihat posisi lainnya",
    noInformation: "Tidak ada informasi perusahaan"
  }
});

export default function SalariesCompaniesCard({ locale = i18nDefaultLocale, company }) {
  i18n.locale = locale;
  i18n.enableFallback = true;
  i18n.defaultLocale = i18nDefaultLocale;

  const classes = useStyles();

  if (!company) {
    return i18n.t("noInformation");
  }

  const { companyTitle, positions } = company;

  const renderSalary = (salary, index) => {
    if (salary.has_user_salaries) {
      return (
        <a href={salary.url} key={index}>
          <div className={`${classes.tag} ${classes.hasUserSalaries}`}>
            {salary.seniority} |{" "}
            <span>
              <span className="font-weight-bold">{`${salary.avgSalary} `}</span>
              {salary.has_verified && (
                <i className={`${classes.tagTick} fa fa-check-circle`} />
              )}
            </span>
          </div>
        </a>
      );
    }
    return (
      <a href={salary.url} key={index}>
        <div className={`${classes.tag} ${classes.noUserSalaries}`}>
          {salary.seniority} |{" "}
          <span className="font-weight-bold">
            {" "}
            {`${salary.avg_job_salaries_min} - ${salary.avg_job_salaries_max}`}
          </span>
        </div>
      </a>
    );
  };

  return (
    <div className={classes.companiesCard}>
      <a className={classes.companiesLink} href={company.url} />
      <div className="d-inline-flex align-items-center">
        <div className={`${classes.companiesImg} flex-shrink-1`}>
          <img
            src={company.companyAvatar || DefaultCompanyAvatar}
            alt={`${companyTitle} logo`}
          />
        </div>
        <div>
          <h3 className={`${classes.title} mb-2`}>{companyTitle}</h3>
          <span className="align-middle">
            <GlassdoorRating glassdoorRating={company.glassdoorRating} />
          </span>
        </div>
      </div>
      {positions.map((position, index) => (
        <div key={index}>
          <p className="font-weight-bold mt-2">{position.title}</p>
          <div className={`${classes.tagHolder} mt-1`}>
            {position.salaries.map((salary, index) =>
              renderSalary(salary, index)
            )}
          </div>
        </div>
      ))}
      {company.morePositions > 0 && (
        <p className={`${classes.more} text-right text-primary mt-3`}>
          +{company.morePositions} {i18n.t("morePositionsCTA")}
        </p>
      )}
    </div>
  );
}

SalariesCompaniesCard.propTypes = {
  locale: PropTypes.string,
  company: PropTypes.shape({
    companyAvatar: PropTypes.string,
    companyTitle: PropTypes.string,
    glassdoorRating: PropTypes.number,
    morePositions: PropTypes.number,
    positions: PropTypes.arrayOf(
      PropTypes.shape({
        avgSalary: PropTypes.string,
        avg_job_salaries_max: PropTypes.string,
        avg_job_salaries_min: PropTypes.string,
        has_user_salaries: PropTypes.bool,
        has_verified: PropTypes.bool,
        seniority: PropTypes.string,
        url: PropTypes.string
      })
    )
  })
};