import React from "react";

export default function GlassdoorRating({ glassdoorRating }) {
  const renderStars = () => {
    const result = [];
    for (let i = 0; i < 5; i++) {
      const currentStarValue = glassdoorRating - i;
      if (currentStarValue >= 0.75) {
        result.push(<i key={i} className="fas fa-star text-secondary" />);
      } else if (currentStarValue >= 0.25) {
        result.push(
          <i key={i} className="fas fa-star-half-alt text-secondary" />
        );
      } else {
        result.push(<i key={i} className="far fa-star text-secondary" />);
      }
    }
    return result;
  };

  return (
    <>
      {(Math.round(glassdoorRating * 10) / 10).toFixed(1)} {renderStars()}{" "}
      (Glassdoor)
    </>
  );
}
