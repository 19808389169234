import React, { Suspense, lazy } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { I18n } from "i18n-js";
import i18nDefaultLocale from "../../constants/i18n";
import cssBreakpoints from "../../constants/cssBreakpoints";
import LoaderCard from "../shared/LoaderCard";

const SmallReviewsCard = lazy(() => import("./SmallReviewsCard"));

const useStyles = createUseStyles({
  container: (props) => {
    return {
      maxWidth: "1140px",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "30px",
      paddingLeft: props.requireHeader ? "14px" : "0px",
      [`@media (max-width: ${cssBreakpoints.tablet})`]: {
        width: "100%"
      }
    };
  },
  header: {
    fontSize: "24px",
    fontWeight: "bold",
    [`@media (max-width: ${cssBreakpoints.tablet})`]: {
      marginLeft: "14px"
    }
  },
  content: {
    borderRadius: "10px",
    padding: "15px 0px",
    overflowY: "hidden",
    display: "flex",
    scrollbarWidth: "none",
    justifyContent: "space-between",
    "-ms-overflow-style": "none",
    [`@media (max-width: ${cssBreakpoints.desktop})`]: {
      justifyContent: "unset"
    },
    "&::-webkit-scrollbar": {
      width: 0,
      height: 0
    }
  },
  card: {
    width: "357px",
    marginRight: "15px",
    flexShrink: "0",
    marginBottom: "15px",
    transition: "0.2s",
    [`@media (max-width: ${cssBreakpoints.tablet})`]: {
      width: "330px",
      marginRight: "10px"
    },
    "&:hover": {
      transform: "translateY(-3px)",
      boxShadow: "0px 4px 16px -2px rgba(0, 0, 0, 0.08)"
    }
  }
});

const i18n = new I18n({
  en: {
    header: "Recent Reviews"
  },
  vn: {
    header: "Đánh giá gần đây"
  },
  id: {
    header: "Ulasan Terbaru"
  }
});

export default function RecentReviewsSection({
  locale = i18nDefaultLocale,
  requireHeader,
  reviewsData
}) {
  i18n.locale = locale;
  i18n.enableFallback = true;
  i18n.defaultLocale = i18nDefaultLocale;

  const classes = useStyles({ requireHeader });

  const { reviewsArray } = JSON.parse(reviewsData);

  return (
    <div className={classes.container}>
      {requireHeader && (
        <div className={classes.header}>{i18n.t("header")}</div>
      )}
      <div className={classes.content}>
        {reviewsArray.map((recentReview) => (
          <div className={classes.card}>
            <Suspense
              fallback={
                <LoaderCard
                  height="250px"
                  boxShadow="none"
                  spinnerSize="3"
                  background="white"
                />
              }
            >
              <SmallReviewsCard
                locale={i18n.locale}
                review={recentReview.review}
                company={recentReview.company}
              />
            </Suspense>
          </div>
        ))}
      </div>
    </div>
  );
}

RecentReviewsSection.propTypes = {
  locale: PropTypes.string,
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      company: PropTypes.shape({
        companyAvatar: PropTypes.string,
        companyName: PropTypes.string
      }),
      review: PropTypes.shape({
        created_at: PropTypes.string,
        description_cons: PropTypes.string,
        description_pros: PropTypes.string,
        id: PropTypes.number,
        link: PropTypes.string,
        ratings: PropTypes.arrayOf(
          PropTypes.shape({
            company_review_id: PropTypes.number,
            name: PropTypes.string,
            value: PropTypes.number
          })
        ),
        slug: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string),
        title: PropTypes.string
      })
    })
  )
};