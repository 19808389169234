const PRIMARY = "#1fc76a";
const SUBTLE_PRIMARY = "#DDF7E9";
const BACKGROUND_COLOR = "#f1f1f1";
const PRIMARY_DARK = "#0e6d39";
const GREY = "#838383";
const WHITE = "#ffffff";
const BLACK = "#000000";
const RATING_0 = "#cccccc";
const RATING_1 = "#d31c1c";
const RATING_2 = "#ff8811";
const RATING_3 = "#e8d300";
const RATING_4 = "#91c71f";
const RATING_5 = "#1fc72b";
const BORDER_GREY = "#E5E7EB";
const LIGHTEST_GREY = "hsl(134, 0%, 95%)";
const MCF_COLOR = "#5E2CA5";
const DARK_GREY = "hsl(0, 0%, 51%)";
const DARK_GREEN = "#1bb660";
const BRAND_GREEN_7 = "#137740";
const LIGHT_BLACK = "#3A3A3A";
const LIGHT_GREY = "#C4C4C4";
const LIGHT_GREEN = "#B7EDCF";
const SUBTLE_RED = "#FAE6E7";

module.exports = {
  PRIMARY,
  SUBTLE_PRIMARY,
  BACKGROUND_COLOR,
  PRIMARY_DARK,
  GREY,
  WHITE,
  BLACK,
  RATING_0,
  RATING_1,
  RATING_2,
  RATING_3,
  RATING_4,
  RATING_5,
  BORDER_GREY,
  LIGHT_GREY,
  LIGHTEST_GREY,
  MCF_COLOR,
  DARK_GREY,
  DARK_GREEN,
  BRAND_GREEN_7,
  LIGHT_BLACK,
  LIGHT_GREEN,
  SUBTLE_RED
};
