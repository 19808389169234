import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { I18n } from "i18n-js";
import i18nDefaultLocale from "../../constants/i18n";
import LandingSalariesRedirect from "../../../assets/images/landing/landing_salaries_redirect.svg";
import { PRIMARY, WHITE } from "../../constants/colors";

const useStyles = createUseStyles({
  card: {
    color: WHITE,
    backgroundColor: PRIMARY,
    padding: "20px",
    borderRadius: "10px",
    height: "100%",
    position: "relative",
    paddingBottom: "60px"
  },
  title: { fontSize: "1rem", fontWeight: "700", marginBottom: "8px" },
  text: {
    fontSize: "0.85rem",
    fontWeight: "400",
    marginTop: "10px",
    marginBottom: "8px"
  },
  redirectContainer: { color: PRIMARY, fontSize: "14px", fontWeight: "700" },
  redirect: {
    backgroundColor: WHITE,
    borderRadius: "5px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "-30px",
    marginTop: "20px"
  }
});

const i18n = new I18n({
  en: {
    salaries: "NodeFlair Salaries",
    topText: "Want to find out how much companies are paying?",
    bottomText:
      "Check out all the latest salary data from user submissions, MyCareersFuture and past job listings!",
    CTA: "View More Salary Data"
  },
  vn: {
    salaries: "Thông Tin Lương NodeFlair",
    topText: 'Bạn muốn tìm hiểu dữ liệu lương các công ty?',
    bottomText: "Hãy xem tất cả dữ liệu lương mới nhất từ các đóng góp của cộng đồng, dữ liệu lương từ nguồn được xác thực và từ các tin tuyển dụng cũ của công ty.",
    CTA: "Xem Thêm Dữ liệu Lương"
  },
  id: {
    salaries: "NodeFlair Gaji",
    topText: 'Ingin tau info gaji pekerja IT di berbagai perusahaan?',
    bottomText: "Cek semua data gaji dari kontribusi pekerja IT, dan data informasi gaji lainnya!",
    CTA: "Lihat Semua Data Gaji"
  }
});

export default function ViewMoreSalariesCard({ locale = i18nDefaultLocale }) {
  i18n.locale = locale;
  i18n.enableFallback = true;
  i18n.defaultLocale = i18nDefaultLocale;

  const classes = useStyles();

  return (
    <div className={classes.card}>
      <div className={classes.title}>{i18n.t("salaries")}</div>
      <div className={classes.text}>{i18n.t("topText")}</div>
      <img src={LandingSalariesRedirect} alt="to nodeflair salaries" />
      <div className={classes.text}>{i18n.t("bottomText")}</div>
      <a className={classes.redirectContainer} href="/salaries">
        <span className={classes.redirect}>{i18n.t("CTA")}</span>
      </a>
    </div>
  );
}

ViewMoreSalariesCard.propTypes = {
  locale: PropTypes.string
};