import ReactOnRails from "react-on-rails";

import SearchSuggestions from "../components/home/SearchSuggestions";
import LandingJobListingView from "../components/jobs/LandingJobListingView";
import HomePageSalariesView from "../components/salaries/HomePageSalariesView";
import RecentReviewsSection from "../components/reviews/RecentReviewsSection";

ReactOnRails.register({
  SearchSuggestions,
  LandingJobListingView,
  HomePageSalariesView,
  RecentReviewsSection
});
