const cssBreakpoints = {
  xs: "350px",
  landscapePhone: "576px",
  tablet: "767.9px",
  large: "992px",
  desktop: "1010px",
  tabletLandscapeMaxWidth: "1119px"
};

export default cssBreakpoints;
