import React, { Suspense, lazy } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import LoaderCard from "../shared/LoaderCard";
import cssBreakpoints from "../../constants/cssBreakpoints";
import { RATING_0 } from "../../constants/colors";
import i18nDefaultLocale from "../../constants/i18n";

const LandingJobListingCard = lazy(() => import("./LandingJobListingCard"));
const useStyles = createUseStyles({
  loadingContainer: {
    textAlign: "center",
    "& .fa": {
      fontSize: "30px",
      color: RATING_0
    }
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100% !important",
    justifyContent: "center !important"
  },
  insightsContainer: { width: "100%" },
  insightsCompanies: {
    borderRadius: "10px",
    padding: "15px 0",
    overflowY: "hidden",
    display: "flex",
    scrollbarWidth: "none" /* Firefox */,
    MsOverflowStyle: "none" /* Internet Explorer 10+ */,
    justifyContent: "space-between",
    [`@media (max-width: ${cssBreakpoints.desktop})`]: {
      justifyContent: "unset"
    },
    "&::-webkit-scrollbar": { width: "0", height: "0" }
  },
  insightsIndividual: {
    width: "330px",
    marginRight: "15px",
    flexShrink: "0",
    [`@media (max-width: ${cssBreakpoints.landscapePhone})`]: {
      width: "310px"
    }
  },
  mobile: { marginLeft: "16px", marginRight: "-15px" }
});

export default function LandingJobListingView({
  locale = i18nDefaultLocale,
  job_listings: jobListing
}) {
  const NUMBER_OF_VISIBLE_CARDS = 4;
  const classes = useStyles();

  function SectionLoader() {
    return (
      <div className={`row ${classes.content}`}>
        <div className={classes.insightsContainer}>
          <div
            className={`${classes.insightsCompanies} no-gutters ${classes.mobile}`}
          >
            {Array.from(Array(NUMBER_OF_VISIBLE_CARDS).keys()).map((index) => (
              <div key={index} className={classes.insightsIndividual}>
                <LoaderCard
                  height="255px"
                  boxShadow="0px 0px 2px rgba(0, 0, 0, 0.25)"
                  spinnerSize="3"
                  background="#F8F8F8"
                  color="grey"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <Suspense fallback={<SectionLoader />}>
      <div className={`row ${classes.content}`}>
        <div className={classes.insightsContainer}>
          <div
            className={`${classes.insightsCompanies} no-gutters ${classes.mobile}`}
          >
            {jobListing?.map((jobListing, index) => (
              <div key={index} className={classes.insightsIndividual}>
                <LandingJobListingCard
                  locale={locale}
                  jobListing={jobListing}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Suspense>
  );
}

LandingJobListingView.propTypes = {
  locale: PropTypes.string,
  job_listings: PropTypes.arrayOf(
    PropTypes.shape({
      company: PropTypes.shape({
        id: PropTypes.number,
        companyname: PropTypes.string,
        avatar: PropTypes.string
      }),
      currency: PropTypes.string,
      id: PropTypes.number,
      is_salary_estimated: PropTypes.bool,
      position: PropTypes.string,
      remuneration_frequency: PropTypes.string,
      salary_max: PropTypes.number,
      salary_min: PropTypes.number,
      formatted_salary_min: PropTypes.string,
      formatted_salary_max: PropTypes.string,
      seniority: PropTypes.arrayOf(PropTypes.string),
      tech_stacks: PropTypes.arrayOf(
        PropTypes.shape({ name: PropTypes.string })
      ),
      time_ago: PropTypes.string,
      title: PropTypes.string
    })
  )
};