import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import ViewMoreSalariesCard from "./ViewMoreSalariesCard";
import LoaderCard from "../shared/LoaderCard";
import SalariesCompaniesCard from "./SalariesCompaniesCard";
import { RATING_0 } from "../../constants/colors";
import i18nDefaultLocale from "../../constants/i18n";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    width: "100%",
    overflowY: "hidden",
    "&::-webkit-scrollbar": {
      width: 0,
      height: 0
    }
  },
  lastCard: {
    width: "357px",
    flexShrink: "0",
    "& > div:first-child": { height: "100%" }
  },
  cardWidth: {
    width: "357px",
    marginRight: "15px",
    flexShrink: "0",
    "& > div:first-child": { height: "100%" }
  },
  loadingContainer: {
    padding: "20px",
    textAlign: "center",
    "& .fa": { fontSize: "30px", color: RATING_0 }
  }
});

export default function HomePageSalariesView({ locale = i18nDefaultLocale, country }) {
  const NUMBER_OF_COMPANIES = 3;
  const classes = useStyles();
  const [popularCompanies, setPopularCompanies] = useState(null);

  const fetchPopularCompanies = useCallback(async () => {
    const popularCompaniesUrl = new URL(
      `/api/v2/salaries/popular_companies?country=${country}&count=${NUMBER_OF_COMPANIES}`,
      window.location.origin
    );

    const popularCompaniesApiResponse = await fetch(popularCompaniesUrl);
    if (!popularCompaniesApiResponse)
      throw new Error(
        `An error has occured: ${popularCompaniesApiResponse.status}`
      );
    const popularCompaniesApiData = await popularCompaniesApiResponse.json();

    setPopularCompanies(popularCompaniesApiData.popularCompanies);
  }, [country]);

  // Fetch Data of popular companies.
  useEffect(() => {
    fetchPopularCompanies();
  }, [fetchPopularCompanies]);

  if (popularCompanies) {
    return (
      <div className={classes.container}>
        {popularCompanies.map((company, i) => (
          <div className={classes.cardWidth} key={i}>
            <SalariesCompaniesCard key={i} locale={locale} company={company} />
          </div>
        ))}
        <div className={classes.lastCard}>
          <ViewMoreSalariesCard locale={locale} />
        </div>
      </div>
    );
  }
  return (
    <div className={classes.container}>
      {Array.from(Array(NUMBER_OF_COMPANIES).keys()).map((idx) => (
        <div className={classes.cardWidth} key={idx}>
          <LoaderCard
            spinnerSize="4"
            height="100%"
            background="white"
            boxShadow="none"
          />
        </div>
      ))}
    </div>
  );
}

HomePageSalariesView.propTypes = {
  country: PropTypes.string,
  locale: PropTypes.string
};