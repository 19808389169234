import React from "react";
import { createUseStyles } from "react-jss";
import { RATING_0, SUBTLE_PRIMARY } from "../../constants/colors";

const useStyles = createUseStyles({
  loaderDiv: (props) => {
    return {
      display: "flex",
      position: "relative",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: props.borderRadius ? props.borderRadius : "15px",
      height: props.height,
      backgroundColor: props.background,
      boxShadow: props.boxShadow,
      color: props.color === "grey" ? RATING_0 : SUBTLE_PRIMARY,
      margin: props.margin ? props.margin : "5px"
    };
  }
});

export default function LoaderCard({ ...props }) {
  const classes = useStyles(props);

  return (
    <div className={classes.loaderDiv}>
      <i className={`fa fa-cog fa-spin fa-${props.spinnerSize}x`} />
    </div>
  );
}
